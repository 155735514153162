import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function TermsOfService() {
  return (
    <Layout>
      <Seo title="Terms Of Service" />
      <section className="hero bg_img" data-background="/bg/hero.jpg">
        <div className="hero__shape">
          <img src="/elements/hero/shape-2.png" alt="no surrender" />
        </div>
        <div className="el-1">
          <img src="/elements/hero/el-1.png" alt="no surrender" />
        </div>
        <div className="el-2">
          <img src="/elements/hero/el-2.png" alt="no surrender" />
        </div>
        <div className="el-3">
          <img src="/elements/hero/el-3.png" alt="no surrender" />
        </div>
        <div className="el-4">
          <img src="/elements/hero/el-4.png" alt="no surrender" />
        </div>
        <div className="el-5">
          <img src="/elements/hero/el-5.png" alt="no surrender" />
        </div>
        <div className="el-6">
          <img src="/elements/hero/el-6.png" alt="no surrender" />
        </div>
        <div className="el-7">
          <img src="/elements/hero/el-7.png" alt="no surrender" />
        </div>
        <div className="el-8">
          <img src="/elements/hero/el-8.png" alt="no surrender" />
        </div>
        <div className="el-9">
          <img src="/elements/hero/el-9.png" alt="no surrender" />
        </div>
        <div className="el-10">
          <img src="/elements/hero/el-10.png" alt="no surrender" />
        </div>
        <div className="el-11">
          <img src="/elements/hero/el-11.png" alt="no surrender" />
        </div>
      </section>
      <section className="pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero__content d-flex flex-column align-items-center">
                <h2
                  className="hero__title wow fadeInUp text-center"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.5s"
                >
                  TERMS OF SERVICE
                </h2>
                <div>
                  <p class="c3">
                    <span class="c0">Effective Date: February 8th, 2023</span>
                  </p>
                  <p class="c3">
                    <span class="c19">
                      The terms of this agreement (&quot;Terms of Service&quot;)
                      govern the relationship between you and{" "}
                    </span>
                    <span class="c19">
                      NoSurrender, a TURKISH company (business ID 6321122630)
                    </span>
                    <span class="c19">
                      &nbsp;having its registered office at Sahkulu mh. Yazarlar
                      sk No:27/5 Galata &#304;stanbul Turkey &nbsp;(hereinafter
                      &quot;NoSurrender&quot; or &quot;Us&quot; or
                      &quot;We&quot;) regarding your use of NoSurrender&#39;s
                      games, store, sites and related services (the
                      &quot;Service&quot;). Use of the Service is also governed
                      by NoSurrender&#39;s{" "}
                    </span>
                    <span class="c19">
                      <a
                        class="c1"
                        href="https://nosurrender.studio/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </span>
                    <span class="c0">
                      The terms of this agreement ("Terms of Service") govern
                      the relationship between you and No Surrender Yazilim ve
                      Ticaret A.S. Before accessing or using the Service,
                      including browsing any No Surrender Yazilim ve Ticaret
                      A.S. website or accessing a game, you must agree to these
                      Terms of Service and the Privacy Policy. A guest account
                      may be created for you for the use of the Service, and you
                      may also be required to register an account on the Service
                      (collectively "Account"). These Accounts include, for
                      example, game Accounts. By using or registering for an
                      Account or otherwise using the Service, you affirm that
                      you are the legal age of majority in your country of
                      residence. If you are not, your legal guardian must review
                      and agree to these Terms of Service. BY INSTALLING, USING
                      OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE
                      TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF
                      SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS
                      THE SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.
                      No Surrender Yazilim ve Ticaret A.S. reserves the right,
                      at its discretion, to change, modify, add or remove
                      portions of these Terms of Service, its Privacy Policy and
                      other relevant No Surrender Yazilim ve Ticaret A.S.
                      policies at any time by posting the amended terms on the
                      Service. You will be deemed to have accepted such changes
                      by continuing to use the Service. If at any point you do
                      not agree to any portion of the then-current version of
                      our Terms of Service, the No Surrender Yazilim ve Ticaret
                      A.S. Privacy Policy, or any other No Surrender Yazilim ve
                      Ticaret A.S. policy, rules or codes of conduct relating to
                      your use of the Service, your right to use the Service
                      shall immediately terminate, and you must immediately stop
                      using the Service.
                      <br />
                      <br />
                      1. Using the Service
                      <br />
                      <br />
                      1.1. Your Right to Use the Service Subject to your
                      agreement and continuing compliance with these Terms of
                      Service and any other relevant No Surrender Yazilim ve
                      Ticaret A.S. policies, you have a non-exclusive,
                      non-transferable, non-sublicensable, revocable and limited
                      right to access and use the Service for your own
                      non-commercial entertainment purposes. You agree not to
                      use the Service for any other purpose. The following
                      restrictions apply to the use of the Service: You accept
                      full responsibility for any unauthorized use of the
                      Service by minors. You are responsible for any use of your
                      credit card or other payment instrument (such as PayPal)
                      by minors. You shall not use the Service to advertise, or
                      solicit, or transmit any commercial advertisements,
                      including chain letters, junk, or spam e-mail or
                      repetitive or misleading messages to anyone.
                      <br />
                      <br />
                      Login information and Your Account You may be required to
                      select a password for your Account, or you may also use
                      other credentials to access the Account ("Login
                      Information"). You shall not share the Account or the
                      Login Information, nor let anyone else access your Account
                      or do anything else that might jeopardize the security of
                      your Account. In the event you become aware of or
                      reasonably suspect any breach of security, including
                      without limitation any loss, theft, or unauthorized
                      disclosure of the Login Information, you must immediately
                      notify No Surrender Yazilim ve Ticaret A.S. and modify
                      your Login Information. You are solely responsible for
                      maintaining the confidentiality of the Login Information,
                      and you will be responsible for all uses of the Login
                      Information, including purchases, whether or not
                      authorized by you. You are responsible for anything that
                      happens through your Account. No Surrender Yazilim ve
                      Ticaret A.S. reserves the right to remove or reclaim any
                      usernames at any time and for any reason, including but
                      not limited to claims by a third party that a username
                      violates the third party's rights. Unless you are using No
                      Surrender ID, the Service supports only one Account per
                      game on a supported device.
                      <br />
                      <br />
                      Use Limitations Any use of the Service in violation of
                      these Use Limitations is strictly prohibited, can result
                      in the immediate revocation of your limited right and may
                      subject you to liability for violations of law. You agree
                      that you will not, under any circumstances: Engage in any
                      act that No Surrender Yazilim ve Ticaret A.S. deems to be
                      in conflict with the spirit or intent of the Service or
                      make improper use of No Surrender Yazilim ve Ticaret A.S.
                      support services. Use or take part (directly or
                      indirectly) in the use of cheats, exploits, automation
                      software, emulators, bots, hacks, mods or any unauthorized
                      third-party software designed to modify or interfere with
                      the Service, any No Surrender Yazilim ve Ticaret A.S. game
                      experience. Modify or cause to be modified any files that
                      are a part of the Service without No Surrender Yazilim ve
                      Ticaret A.S. express written consent. Disrupt, interfere
                      with or otherwise adversely affect the normal flow of the
                      Service or otherwise act in a manner that may negatively
                      affect other users' experience when using the Service.
                      This includes win trading and any other kind of
                      manipulation of rankings, taking advantage of errors in
                      the Service to gain an unfair edge over other players and
                      any other act that intentionally abuses or goes against
                      the design of the Service. Disrupt, overburden, or aid or
                      assist in the disruption or overburdening of any computer
                      or server used to offer or support the Service. Institute,
                      assist, or become involved in any type of attack,
                      including without limitation distribution of a virus,
                      denial of service attacks upon the Service, or other
                      attempts to disrupt the Service or any other person's use
                      or enjoyment of the Service. Attempt to gain unauthorized
                      access to the Service, Accounts registered or used by
                      others or to the computers, servers, or networks connected
                      to the Service by any means other than the user interface
                      provided by No Surrender Yazilim ve Ticaret A.S. ,
                      including but not limited to, by circumventing or
                      modifying, attempting to circumvent or modify, or
                      encouraging or assisting any other person to circumvent or
                      modify, any security, technology, device, or software that
                      is part of the Service. Post any information that is
                      abusive, threatening, obscene, defamatory, libelous, or
                      racially, sexually, religiously, or otherwise
                      objectionable or offensive or engage in ongoing toxic
                      behavior, such as by repeatedly posting information on an
                      unsolicited basis. Post any information that contains
                      nudity, excessive violence, or offensive subject matter or
                      that contains a link to such content. Attempt to, or
                      harass, abuse, or harm, or advocate or incite harassment,
                      abuse, or harm of another person, group, including No
                      Surrender Yazilim ve Ticaret A.S. employees, including No
                      Surrender Yazilim ve Ticaret A.S. customer service
                      representatives. Make available through the Service any
                      material or information that infringes any copyright,
                      trademark, patent, trade secret, right of privacy, right
                      of publicity, or other right of any person or entity or
                      impersonates any other person, including without
                      limitation a No Surrender Yazilim ve Ticaret A.S.
                      employee. Reverse engineer, decompile, disassemble,
                      decipher or otherwise attempt to derive the source code
                      for any underlying software or other intellectual property
                      used to provide the Service or any No Surrender Yazilim ve
                      Ticaret A.S. game, or to obtain any information from the
                      Service or any No Surrender Yazilim ve Ticaret A.S. game
                      using any method not expressly permitted by No Surrender
                      Yazilim ve Ticaret A.S. . Solicit, or attempt to solicit,
                      Login Information or any other login credentials or
                      personal information from other users of the Service or
                      any No Surrender Yazilim ve Ticaret A.S. game. Collect or
                      post anyone's private information, including personally
                      identifiable information (whether in text, image or video
                      form), identification documents, or financial information
                      through the Service. Use any No Surrender Yazilim ve
                      Ticaret A.S. game for gambling, betting or any similar
                      activity in which prizes or rewards can be won (directly
                      or indirectly), including betting on the outcome of
                      matches in which you participate as a player, irrespective
                      of whether or not there is a fee or stake involved. Use
                      the Service in any way that would violate any export
                      controls, anti-money laundering rules, economic sanctions
                      or similar laws or regulations including but not limited
                      to those imposed by the United States of America and/or
                      the European Union. No Surrender Yazilim ve Ticaret A.S.
                      reserves the right to determine what conduct it considers
                      to be in violation of the rules of use or otherwise
                      outside the intent or spirit of these Terms of Service or
                      the Service itself. No Surrender Yazilim ve Ticaret A.S.
                      reserves the right to take action as a result, which may
                      include terminating your Account and prohibiting you from
                      using the Service in whole or in part.
                      <br />
                      <br />
                      1.2. Suspension and Termination of Account and Service
                      WITHOUT LIMITING ANY OTHER REMEDIES, NO SURRENDER Yazilim
                      ve Ticaret A.S. MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR
                      DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS
                      THEREOF WITH OR WITHOUT NOTICE TO YOU (i) IF YOU ARE, OR
                      No Surrender Yazilim ve Ticaret A.S. SUSPECTS THAT YOU
                      ARE, FAILING TO COMPLY WITH THESE TERMS OF SERVICE; OR
                      (ii) FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE
                      OF THE SERVICE. YOU CAN LOSE YOUR USERNAME AND PERSONA IN
                      THE SERVICE AS A RESULT OF ACCOUNT TERMINATION OR
                      LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED
                      VIRTUAL ITEMS AND PURCHASED VIRTUAL ITEMS ASSOCIATED WITH
                      YOUR USE OF THE SERVICE, AND No Surrender Yazilim ve
                      Ticaret A.S. IS UNDER NO OBLIGATION TO COMPENSATE YOU FOR
                      ANY SUCH LOSSES OR RESULTS. WITHOUT LIMITING OUR OTHER
                      REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE
                      AND ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR
                      GAMES AND SITES, AND THEIR CONTENT, SERVICES AND TOOLS,
                      DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND
                      LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF
                      WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL
                      LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS
                      OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER
                      OR SPIRIT OF OUR TERMS OR POLICIES. ADDITIONALLY, WE MAY,
                      IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION,
                      SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT
                      INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS. No
                      Surrender Yazilim ve Ticaret A.S. RESERVES THE RIGHT TO
                      TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS.
                      No Surrender Yazilim ve Ticaret A.S. reserves the right to
                      stop offering and/or supporting the Service or a
                      particular game or part of the Service at any time, at
                      which point your right to use the Service or a part
                      thereof will be automatically terminated. In such event,
                      No Surrender Yazilim ve Ticaret A.S. shall not be required
                      to provide refunds, benefits or other compensation to
                      users in connection with such discontinued Service.
                      Termination of your Account can include disabling your
                      access to the Service or any part thereof including any
                      content you submitted or others submitted. You may
                      terminate your Account at any time and for any reason by
                      following the process described on our support page
                      located at our website informing No Surrender Yazilim ve
                      Ticaret A.S. that you wish to terminate your Account.
                      <br />
                      <br />
                      2. Ownership
                      <br />
                      <br />
                      2.1. Games and Service All rights, title and interest in
                      and to the Service (including without limitation any
                      games, titles, computer code, themes, objects, characters,
                      character names, stories, dialogue, catch phrases,
                      concepts, artwork, animations, sounds, musical
                      compositions, audio-visual effects, methods of operation,
                      moral rights, documentation, in-game chat transcripts,
                      character profile information, recordings of games played
                      using a No Surrender Yazilim ve Ticaret A.S. game client,
                      and the No Surrender Yazilim ve Ticaret A.S. game clients
                      and server software) are owned by No Surrender Yazilim ve
                      Ticaret A.S. . No Surrender Yazilim ve Ticaret A.S.
                      reserves all rights, including without limitation, all
                      intellectual property rights or other proprietary rights,
                      in connection with its games and the Service. Your use of
                      the Service shall not deemed as a license or assigning of
                      any right from No Surrender Yazilim ve Ticaret A.S. to
                      you, except as expressly and specifically set forth in
                      these Terms of Service.
                      <br />
                      <br />
                      2.2. Accounts NOT WITHSTANDING ANYTHING TO THE CONTRARY
                      HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO
                      OWNERSHIP OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND
                      YOU FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND
                      TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE
                      TO THE BENEFIT OF No Surrender Yazilim ve Ticaret A.S. .
                      <br />
                      <br />
                      2.3. Virtual Content No Surrender Yazilim ve Ticaret A.S.
                      owns, has licensed, or otherwise has rights to use all of
                      the content that appears in the Service or in No Surrender
                      Yazilim ve Ticaret A.S. games. Notwithstanding any
                      provision to the contrary herein, you agree that you have
                      no right or title in or to any content that appears in the
                      Service, including without limitation the virtual items,
                      content, features, goods, services or currency appearing
                      or originating in any No Surrender Yazilim ve Ticaret A.S.
                      game, whether earned in a game or purchased from No
                      Surrender Yazilim ve Ticaret A.S., or any other attributes
                      associated with an Account or stored on the Service.
                      <br />
                      <br />
                      3. User Content
                      <br />
                      <br />
                      3.1. Submission of User Content "User Content" means any
                      communications, images, sounds, and all the material,
                      data, and information that you upload or transmit through
                      a No Surrender Yazilim ve Ticaret A.S. game client or the
                      Service, or that other users upload or transmit, including
                      without limitation any chat text. By transmitting or
                      submitting any User Content while using the Service, you
                      affirm, represent and warrant that such transmission or
                      submission is (a) accurate and not confidential or
                      misleading; (b) not in violation of any laws, contractual
                      restrictions or other third party rights, and that you
                      have permission from any third party whose personal
                      information or intellectual property is comprised in the
                      User Content; (c) free of viruses, adware, spyware, worms
                      or other malicious code; and (d) you acknowledge and agree
                      that any of your personal information within such content
                      will at all times be processed by No Surrender Yazilim ve
                      Ticaret A.S. in accordance with its Privacy Policy.
                      <br />
                      <br />
                      3.1.1. Content Screening No Surrender Yazilim ve Ticaret
                      A.S. assumes no responsibility for the conduct of any user
                      submitting any User Content and assumes no responsibility
                      for monitoring the Service for inappropriate content or
                      conduct. We do not, and cannot, pre-screen or monitor all
                      User Content. Your use of the Service is at your own risk.
                      By using the Service, you may be exposed to User Content
                      that is offensive, indecent, or otherwise not in line with
                      your expectations. You bear all risks associated with the
                      use of any User Content available in connection with the
                      Service. At our discretion, our representatives or
                      technology may monitor and/or record your interaction with
                      the Service or communications (including without
                      limitation chat text) when you are using the Service. By
                      entering into these Terms of Service, you hereby provide
                      your irrevocable consent to such monitoring and recording.
                      You acknowledge and agree that you have no expectation of
                      privacy concerning the transmission of any User Content,
                      including without limitation chat text or voice
                      communications. No Surrender Yazilim ve Ticaret A.S.
                      reserves the right in its sole discretion to review,
                      monitor, prohibit, edit, delete, disable access to or
                      otherwise make unavailable any User Content (including
                      without limitation your User Content) without notice for
                      any reason or for no reason at any time. If at any time No
                      Surrender Yazilim ve Ticaret A.S. chooses, in its sole
                      discretion, to monitor the Service, No Surrender Yazilim
                      ve Ticaret A.S. nonetheless assumes no responsibility for
                      User Content and assumes no obligation to modify or remove
                      any inappropriate User Content. We have the right, but not
                      the obligation, in our sole discretion to edit, refuse to
                      post, or remove any User Content.
                      <br />
                      <br />
                      3.2. Information Use by Other Members of the Service
                      <br />
                      <br />
                      3.2.1. Public Discourse The Service may include various
                      forums, blogs and chat features where you can post User
                      Content, including your observations and comments on
                      designated topics. No Surrender Yazilim ve Ticaret A.S.
                      cannot guarantee that other members will not use the ideas
                      and information that you share. Therefore, if you have an
                      idea or information that you would like to keep
                      confidential and/or don't want others to use, do not post
                      it on the Service. No Surrender Yazilim ve Ticaret A.S.
                      shall have no responsibility to evaluate, use or
                      compensate you for any ideas or information you may choose
                      to submit.
                      <br />
                      <br />
                      3.2.2. Responsible For Your Own Content You are solely
                      responsible for the information that you post on, through
                      or in connection with the Service and that you provide to
                      others. No Surrender Yazilim ve Ticaret A.S. may reject,
                      refuse to post or delete any User Content for any or no
                      reason, including, but not limited to, User Content that
                      in the sole judgment of No Surrender Yazilim ve Ticaret
                      A.S. violates these Terms of Service.
                      <br />
                      <br />
                      3.2.3. Your License to No Surrender Yazilim ve Ticaret
                      A.S. You hereby grant to No Surrender Yazilim ve Ticaret
                      A.S. an irrevocable, perpetual, transferable, fully
                      paid-up, royalty-free, worldwide license (including the
                      right to sublicense and assign to third party) and right
                      to copy, reproduce, fix, adapt, modify, create derivative
                      works from, manufacture, commercialize, publish,
                      distribute, sell, license, sublicense, transfer, lease,
                      transmit, publicly display, publicly perform, or provide
                      access to electronically, broadcast, communicate to the
                      public by telecommunication, display, perform, enter into
                      computer memory, and use and practice, in any way, your
                      User Content as well as all modified and derivative works
                      thereof in connection with our provision of the Service,
                      including marketing and promotions of the Service. You
                      also hereby grant to No Surrender Yazilim ve Ticaret A.S.
                      the right to authorize others to exercise any of the
                      rights granted to No Surrender Yazilim ve Ticaret A.S.
                      under these Terms of Service. You further hereby grant to
                      No Surrender Yazilim ve Ticaret A.S. the unconditional,
                      irrevocable right to use and exploit your name, likeness
                      and any other information or material included in any User
                      Content and in connection with any User Content, without
                      any obligation to you. Except as prohibited by law, you
                      waive any rights of attribution and/or any moral rights
                      you may have in your User Content, regardless of whether
                      your User Content is altered or changed in any manner. No
                      Surrender Yazilim ve Ticaret A.S. does not claim any
                      ownership rights in your User Content and nothing in these
                      Terms of Service is intended to restrict any rights that
                      you may have to use and exploit your User Content. No
                      Surrender Yazilim ve Ticaret A.S. has no obligation to
                      monitor or enforce your intellectual property rights in or
                      to your User Content.
                      <br />
                      <br />
                      3.3. User Interactions You are solely responsible for your
                      interactions with other users of the Service and any other
                      parties with whom you interact through the Service and/or
                      No Surrender Yazilim ve Ticaret A.S. games. No Surrender
                      Yazilim ve Ticaret A.S. reserves the right, but has no
                      obligation, to become involved in any way with these
                      disputes. You will fully cooperate with No Surrender
                      Yazilim ve Ticaret A.S. to investigate any suspected
                      unlawful, fraudulent, or improper activity, including,
                      without limitation, granting No Surrender Yazilim ve
                      Ticaret A.S. access to any password-protected portions of
                      your Account. If you have a dispute with one or more
                      users, you release us (and our officers, directors,
                      agents, subsidiaries, joint ventures and employees) from
                      claims, demands and damages (actual and consequential) of
                      every kind and nature, known and unknown, arising out of
                      or in any way connected with such disputes.
                      <br />
                      <br />
                      4. Fees and Purchase Terms
                      <br />
                      <br />
                      4.1.Purchases In the Service you may purchase, with "real
                      world" money, a limited, personal, non-transferable,
                      non-sublicensable, revocable right to use (a) virtual
                      currency, including but not limited to virtual cash or
                      diamonds, all for use in No Surrender Yazilim ve Ticaret
                      A.S. games; (b) virtual in-game items, content or
                      features; and (c) other goods or services (points a – c
                      are jointly referred to as "Virtual Items"). You are only
                      allowed to purchase Virtual Items from us or our
                      authorized partners through the Service, and not in any
                      other way. You expressly agree that we will start the
                      performance of the purchase agreement of Virtual Items or
                      any other digital content before the expiration of any
                      statutory withdrawal period defined in applicable laws.
                      You give explicit prior consent that the content you have
                      purchased will be delivered to you before the withdrawal
                      period ends and you thereby loose the statutory right of
                      withdrawal. No Surrender Yazilim ve Ticaret A.S. may
                      manage, regulate, control, modify or eliminate Virtual
                      Items at any time, with or without notice. To the fullest
                      extent under applicable law, No Surrender Yazilim ve
                      Ticaret A.S. shall have no liability to you or any third
                      party in the event that No Surrender Yazilim ve Ticaret
                      A.S. exercises any such rights. The transfer of Virtual
                      Items is prohibited except where expressly authorized in
                      the Service. Other than as expressly authorized in the
                      Service, you shall not sell, purchase, redeem or otherwise
                      transfer Virtual Items to any person or entity or attempt
                      any of the aforesaid, including but not limited to No
                      Surrender Yazilim ve Ticaret A.S. , another user or any
                      third party. Except where expressly authorized in the
                      Service, Virtual Items that are authorized by the Service
                      to traded, transferred or otherwise transacted could be
                      purchased, sold, swapped, claimed or become subject to
                      other relevant transactions on the No Surrender NFT
                      Marketplace. Please refer to the NFT Marketplace Terms and
                      you shall be deemed you accepted the NFT Marketplace terms
                      by using the No Surrender NFT Marketplace. ALL PURCHASES
                      AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE
                      ARE FINAL AND NON-REFUNDABLE EXCEPT WHERE REQUIRED UNDER
                      APPLICABLE LAW. Please see our Parents Guide for more
                      information regarding purchases made by minors. The
                      provision of Virtual Items for use in No Surrender Yazilim
                      ve Ticaret A.S. games is a service provided by No
                      Surrender Yazilim ve Ticaret A.S. that commences
                      immediately upon acceptance by No Surrender Yazilim ve
                      Ticaret A.S. of your purchase.
                      <br />
                      <br />
                      4.2. Payment of Fees You agree to pay all fees and
                      applicable taxes incurred by you or anyone using an
                      Account registered to you. No Surrender Yazilim ve Ticaret
                      A.S. may revise the pricing for the Virtual Items offered
                      through the Service at any time. YOU ACKNOWLEDGE THAT No
                      Surrender Yazilim ve Ticaret A.S. IS NOT REQUIRED TO
                      PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT
                      RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL
                      ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
                      VOLUNTARY OR INVOLUNTARY.
                      <br />
                      <br />
                      5. Updates to the Service You understand that the Service
                      is an evolving one. No Surrender Yazilim ve Ticaret A.S.
                      may require that you accept updates to the Service and to
                      No Surrender Yazilim ve Ticaret A.S.'s games you have
                      installed on your device or computer. You acknowledge and
                      agree that No Surrender Yazilim ve Ticaret A.S. may update
                      the Service and No Surrender Yazilim ve Ticaret A.S.
                      games, with or without notifying you. You may need to
                      update third party software from time to time in order to
                      receive the Service and play No Surrender Yazilim ve
                      Ticaret A.S. games.
                      <br />
                      <br />
                      6. Disclaimer of Warranties WITHOUT LIMITING No Surrender
                      Yazilim ve Ticaret A.S.'S LIABILITY UNDER SECTION 7 BELOW,
                      THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                      BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND,
                      EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM
                      COURSE OF DEALING OR USAGE OF TRADE. No Surrender Yazilim
                      ve Ticaret A.S. DOES NOT WARRANT THAT YOU WILL BE ABLE TO
                      ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF
                      YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR
                      ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE
                      GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL
                      COMPONENTS. Some jurisdictions do not allow the exclusion
                      of certain warranties. Accordingly, some of the above
                      disclaimers may not apply to you.
                      <br />
                      <br />
                      7. Limitation of Liability; Sole and Exclusive Remedy;
                      Indemnification TO THE MAXIMUM EXTENT PERMITTED BY LAW, No
                      Surrender Yazilim ve Ticaret A.S. SHALL NOT BE LIABLE TO
                      YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                      PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT
                      LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR
                      BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER
                      SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN
                      ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF,
                      WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY,
                      AND WHETHER OR NOT No Surrender Yazilim ve Ticaret A.S.
                      HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO
                      THE EXTENT NOT PROHIBITED BY LAW, No Surrender Yazilim ve
                      Ticaret A.S. SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE
                      AMOUNT YOU HAVE PAID TO No Surrender Yazilim ve Ticaret
                      A.S. IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX
                      (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU
                      FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF
                      YOU HAVE NOT PAID ANYTHING TO No Surrender Yazilim ve
                      Ticaret A.S. DURING SUCH TIME PERIOD, YOUR SOLE REMEDY
                      (AND No Surrender Yazilim ve Ticaret A.S.'S EXCLUSIVE
                      LIABILITY) FOR ANY DISPUTE WITH No Surrender Yazilim ve
                      Ticaret A.S. IS TO STOP USING THE SERVICE AND TO CANCEL
                      YOUR ACCOUNT. NOTHING IN THESE TERMS OF SERVICE SHALL
                      AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR
                      RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR
                      WILLFUL MISCONDUCT OF No Surrender Yazilim ve Ticaret A.S.
                      OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY
                      NEGLIGENCE OR FRAUD OF No Surrender Yazilim ve Ticaret
                      A.S.. You agree to indemnify, defend and hold No Surrender
                      Yazilim ve Ticaret A.S. (and our officers, directors,
                      agents, subsidiaries, joint ventures and employees)
                      harmless from any claim, demand, damages or other losses,
                      including reasonable attorneys' fees, asserted by any
                      third-party resulting from or arising out of your use of
                      the Service, or any breach by you of these Terms of
                      Service, however the foregoing does not apply if the
                      infringement of rights is not attributable to your
                      intentional or negligent behavior.
                      <br />
                      <br />
                      8. Dispute Resolution You and No Surrender Yazilim ve
                      Ticaret A.S. agree that the processes for dispute
                      resolution described in this agreement will apply to any
                      dispute or claims related to these Terms of Service, the
                      Privacy Policy, or the Service. Disputes include any
                      claims of any kind, including but not limited to legal,
                      equitable, or statutory claims. Processes for dispute
                      resolution will apply even if you stop using your Account,
                      delete your Account, or stop using the Service. They will
                      also apply to disputes that arose before we entered into
                      this agreement.
                      <br />
                      <br />
                      8.1. Informal Dispute Resolution You must try to
                      informally resolve any dispute directly with No Surrender
                      Yazilim ve Ticaret A.S. for at least thirty (30) days
                      before you start an arbitration. The informal dispute
                      resolution process starts when you give No Surrender
                      Yazilim ve Ticaret A.S. written notice of the dispute
                      through community@nosurrender.studio
                      <br />
                      <br />
                      8.2. Arbitration Agreement You and No Surrender Yazilim ve
                      Ticaret A.S. agree to resolve any disputes exclusively in
                      final and binding arbitration as follows: Either you or No
                      Surrender Yazilim ve Ticaret A.S. may choose to submit any
                      dispute for resolution exclusively by final and binding
                      arbitration unless the claim is within the exceptions
                      described below. If you or No Surrender Yazilim ve Ticaret
                      A.S. brings a claim in court that can be resolved by
                      arbitration under this section, then either party can ask
                      the court to order the parties to resolve the claim by
                      arbitration. The arbitrator will have the exclusive
                      authority to decide whether any portion of Section 8
                      (“Dispute Resolution”) is valid or enforceable, or whether
                      it applies to a claim. An arbitration proceeding will be
                      held before a neutral arbitrator. This means you and No
                      Surrender Yazilim ve Ticaret A.S. agree to give up the
                      right to resolve the dispute in a trial before a judge or
                      jury. Arbitration has different rules than more formal
                      lawsuits. For example, the ability to force the other side
                      to share information may be more limited than the process
                      called discovery in formal lawsuits. After the arbitrator
                      decides the outcome, that decision will be final. You or
                      No Surrender Yazilim ve Ticaret A.S. can ask the
                      arbitrator to put a decision or award and the reasons for
                      it in writing. Either of us can ask a court to confirm or
                      enter the arbitrator’s final decision or award, which will
                      make it the same as a court judgment. You and No Surrender
                      Yazilim ve Ticaret A.S. will generally not be able to
                      change the outcome of arbitration through courts outside
                      of very limited circumstances.
                      <br />
                      <br />
                      8.3. Arbitration Process The arbitration will be run by
                      the American Arbitration Association (“AAA”). AAA’s rules
                      and procedures will be used for the arbitration, including
                      the Consumer Arbitration Rules. But if there is a conflict
                      between these Terms of Service and AAA’s rules and
                      procedures, then we will follow these Terms of Service. To
                      review AAA’s Rules or to start arbitration, you can go
                      to AAA’s website. If either of us decide to start
                      arbitration, we agree to provide the other party with a
                      written Demand for Arbitration as specified in the AAA
                      Rules. The fees for arbitration will be determined by
                      AAA’s Consumer Arbitration Rules. If the arbitrator
                      decides that those fees are excessive, No Surrender
                      Yazilim ve Ticaret A.S. will pay the fees. Each side will
                      pay their own attorneys’ fees and costs unless the claims
                      allow for to the prevailing party to recover attorneys’
                      fees and costs, in which case the arbitrator may award
                      them under the applicable law. If either party
                      unsuccessfully challenges the validity of the arbitrator’s
                      decision or award through a subsequent court case, the
                      unsuccessful party shall pay the opposing party’s costs
                      and attorneys’ fees associated with the challenge. The
                      arbitration will take place either in San Francisco,
                      California, USA or in the county or province where you
                      reside.
                      <br />
                      <br />
                      8.4 Exceptions to Agreement to Arbitrate You and No
                      Surrender Yazilim ve Ticaret A.S. agree that the
                      arbitration agreement in Section 8.2 will not apply to the
                      following disputes: Claims about No Surrender Yazilim ve
                      Ticaret A.S.’s intellectual property, such as claims to
                      enforce, protect, or concerning the validity of No
                      Surrender Yazilim ve Ticaret A.S.’s copyrights,
                      trademarks, trade dress, domain names, patents, trade
                      secrets, or other intellectual property rights. Claims
                      related to piracy or tortious interference. Claims that
                      are not subject to an arbitration agreement as a matter of
                      law and are not preempted by federal law that would allow
                      for an agreement to arbitration. Claims in small claims
                      court. Any dispute not subject to arbitration under these
                      exceptions shall be resolved by a court of competent
                      jurisdiction as described in Section 10 (“Venue for
                      Disputes Not Subject to Arbitration”).
                      <br />
                      <br />
                      8.5 No Class Actions You and No Surrender Yazilim ve
                      Ticaret A.S. agree that we can only bring claims against
                      each other on an individual basis.That means: You cannot
                      bring a claim against No Surrender Yazilim ve Ticaret A.S.
                      as a plaintiff or class member in a class, collective,
                      consolidated, or representative action. The arbitrator
                      cannot combine any other person’s claims with yours into a
                      single case or preside over any class, collective,
                      consolidated, or representative arbitration proceeding.
                      The arbitrator’s decision or award in your case will not
                      apply to anyone else and cannot be used to decide other
                      people’s disputes. If this section (Section 8.5 “No Class
                      Actions”) is found to be unenforceable or invalid, then
                      the entirety of Section 8, including Sections 8.1 to 8.6,
                      shall be void.
                      <br />
                      <br />
                      8.6 Opt-Out of Arbitration Agreement and No Class Actions
                      Provisions You can choose to opt out of and not be bound
                      by the Arbitration Agreement and No Class Action
                      provisions above (Sections 8.1 to 8.5) by sending written
                      notice of your decision to opt out to
                      community@nosurrender.studio with the subject line
                      “ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.” You must
                      send us this notice within thirty (30) days of your first
                      use of the Service or availability of this opt-out,
                      whichever is later. If you do not send us a notice within
                      that time, you will be bound to arbitrate disputes in
                      accordance with the terms of these paragraphs. If you opt
                      out of these arbitration provisions, No Surrender Yazilim
                      ve Ticaret A.S. also will not be bound by them.
                      <br />
                      <br />
                      8.7 Alternative and Online Dispute Resolution If you are a
                      consumer based in the EU or EEA, you may make use of the
                      Online Dispute Resolution platform offered by the European
                      Commission as an out-of-court alternative to resolve
                      disputes. You may also recourse to alternative dispute
                      resolution and refer a dispute to the consumer disputes
                      board or other corresponding out-of-court institution in
                      your domicile.
                      <br />
                      <br />
                      9 Applicable Law If you are a resident of the United
                      States or Canada: (1) the United States Federal
                      Arbitration Act (including its procedural provisions)
                      governs the interpretation and enforcement of the
                      agreement to arbitrate and no class actions provisions of
                      Section 8, and (2) any dispute arising out of or related
                      to these Terms of Service, the Privacy Policy, or the
                      Service shall be governed in all respects by the laws of
                      the State of California, USA without regard to conflict of
                      law provisions. If you are a resident outside of the
                      United States and Canada, you agree that any claim or
                      dispute arising out of or related to these Terms of
                      Service, the Privacy Policy, or the Service shall be
                      governed by the laws of Finland without regard to conflict
                      of law provisions.
                      <br />
                      <br />
                      10 Venue for Disputes Not Subject to Arbitration If you
                      are a resident of the United States or Canada, you agree
                      that any claim or dispute you may have against No
                      Surrender Yazilim ve Ticaret A.S. that is not subject to
                      arbitration under Section 8 must be resolved exclusively
                      by a federal or state court located in San Francisco,
                      California. You and No Surrender Yazilim ve Ticaret A.S.
                      consent to venue and personal jurisdiction in San
                      Francisco, California for all such claims or disputes. If
                      you are a resident outside of the United States and
                      Canada, you agree that any claim or dispute you may have
                      against No Surrender Yazilim ve Ticaret A.S. must be
                      resolved exclusively by a court located in Helsinki,
                      Finland.
                      <br />
                      <br />
                      11. Severability You and No Surrender Yazilim ve Ticaret
                      A.S. agree that if any portion of these Terms of Service
                      or of the No Surrender Yazilim ve Ticaret A.S. Privacy
                      Policy is found illegal or unenforceable, in whole or in
                      part by any court of competent jurisdiction, such
                      provision shall, as to such jurisdiction, be ineffective
                      solely to the extent of such determination of invalidity
                      or unenforceability without affecting the validity or
                      enforceability thereof in any other manner or jurisdiction
                      and without affecting the remaining provisions of the
                      terms, which shall continue to be in full force and
                      effect.
                      <br />
                      <br />
                      12. General Provisions
                      <br />
                      <br />
                      12.1. Assignment No Surrender Yazilim ve Ticaret A.S. may
                      assign or delegate these Terms of Service and/or the No
                      Surrender Yazilim ve Ticaret A.S. Privacy Policy, in whole
                      or in part, to any person or entity at any time with or
                      without your consent. You may not assign or delegate any
                      rights or obligations under the Terms of Service or
                      Privacy Policy without No Surrender Yazilim ve Ticaret
                      A.S.'s prior written consent, and any unauthorized
                      assignment and delegation by you is ineffective.
                      <br />
                      <br />
                      12.2. Supplemental Policies No Surrender Yazilim ve
                      Ticaret A.S. may publish additional policies related to
                      specific services such as forums, contests, or loyalty
                      programs. Your right to use such services is subject to
                      those specific policies and these Terms of Service.
                      <br />
                      <br />
                      12.3. Entire Agreement These Terms of Service, any
                      supplemental policies and any documents expressly
                      incorporated by reference herein (including the No
                      Surrender Yazilim ve Ticaret A.S. Privacy Policy), contain
                      the entire understanding of you and No Surrender Yazilim
                      ve Ticaret A.S., and supersede all prior understandings of
                      the parties hereto relating to the subject matter hereof,
                      whether electronic, oral or written, or whether
                      established by custom, practice, policy or precedent,
                      between you and us with respect to the Service.
                      <br />
                      <br />
                      12.4. No Waiver The failure of No Surrender Yazilim ve
                      Ticaret A.S. to require or enforce strict performance by
                      you of any provision of these Terms of Service or the No
                      Surrender Yazilim ve Ticaret A.S. Privacy Policy or
                      failure to exercise any right under them shall not be
                      construed as a waiver or relinquishment of No Surrender
                      Yazilim ve Ticaret A.S.'s right to assert or rely upon any
                      such provision or right in that or any other instance. The
                      express waiver by No Surrender Yazilim ve Ticaret A.S. of
                      any provision, condition, or requirement of these Terms of
                      Service or the No Surrender Yazilim ve Ticaret A.S.
                      Privacy Policy shall not constitute a waiver of any future
                      obligation to comply with such provision, condition or
                      requirement. Except as expressly and specifically set
                      forth in this these Terms of Service, no representations,
                      statements, consents, waivers, or other acts or omissions
                      by No Surrender Yazilim ve Ticaret A.S. shall be deemed a
                      modification of these Terms of Service nor legally
                      binding, unless documented in physical writing, hand
                      signed by You and a duly appointed officer of No Surrender
                      Yazilim ve Ticaret A.S..
                      <br />
                      <br />
                      12.5. Notices We may notify you via postings in our games,
                      on No Surrender Yazilim ve Ticaret A.S..com, via e-mail or
                      any other communications means to contact information you
                      provide to us. e-mail: community@nosurrender.studio
                      <br />
                      <br />
                      12.6. Equitable Remedies You acknowledge that the rights
                      granted and obligations made under these Terms of Service
                      to No Surrender Yazilim ve Ticaret A.S. are of a unique
                      and irreplaceable nature, the loss of which shall
                      irreparably harm No Surrender Yazilim ve Ticaret A.S. and
                      which cannot be replaced by monetary damages alone so that
                      No Surrender Yazilim ve Ticaret A.S. shall be entitled to
                      injunctive or other equitable relief (without the
                      obligations of posting any bond or surety or proof of
                      damages) in the event of any breach or anticipatory breach
                      by you. You irrevocably waive all rights to seek
                      injunctive or other equitable relief, or to enjoin or
                      restrain the operation of the Service or any No Surrender
                      Yazilim ve Ticaret A.S. game, exploitation of any
                      advertising or other materials issued in connection
                      therewith, or exploitation of the Service or any content
                      or other material used or displayed through the Service
                      and agree to limit your claims to claims for monetary
                      damages, limited by Section 7 (if any).
                      <br />
                      <br />
                      12.7. Force Majeure No Surrender Yazilim ve Ticaret A.S.
                      shall not be liable for any delay or failure to perform
                      resulting from causes outside the reasonable control of No
                      Surrender Yazilim ve Ticaret A.S., including without
                      limitation any failure to perform hereunder due to
                      unforeseen circumstances or cause beyond No Surrender
                      Yazilim ve Ticaret A.S. control such as acts of God, war,
                      terrorism, riots, embargoes, acts of civil or military
                      authorities, fire, floods, accidents, strikes, or
                      shortages of transportation facilities, fuel, energy,
                      labor or materials. Get news and updates as soon
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsOfService
